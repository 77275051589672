import api from "../axios_service.js";

export async function fetchEquipe(filtros) {
  const response = await api.get(
    `/colaborador/empresa-equipe${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchEquipeIndividuo(id) {
  const response = await api.get(`/colaborador/empresa-equipe/${id}`);
  return response.data.result;
}

export function putEquipeIndividuo(id, item) {
  return api.post(`/colaborador/empresa-equipe/${id}`, item);
}

export async function postEquipeIndividuo(item) {
  const response = await api.post("/colaborador/empresa-equipe/add", item);
  return response;
}

export async function uploadAvatarColaborador(id, body) {
  const response = await api.post(
    `/colaborador/empresa-equipe/${id}/avatar`,
    body
  );
  return response;
}
